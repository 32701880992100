@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("./Assets/Fonts/CabinetGrotesk/CabinetGrotesk-Bold.otf");
}
@font-face {
  font-family: 'Duplicate Sans';
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-Bold.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-BoldItalic.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-Heavy.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-HeavyItalic.ttf');
  src: url("./Assets/Fonts/DuplicateSans/DuplicateSans-Italic.ttf");
  src: url("./Assets/Fonts/DuplicateSans/DuplicateSans-Light.ttf");
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-LightItalic.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-Medium.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-MediumItalic.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-Thin.ttf');
  src: url('./Assets/Fonts/DuplicateSans/DuplicateSans-ThinItalic.ttf');
  src: url("./Assets/Fonts/DuplicateSans/DuplicateSans.ttf");
}

body {
  margin: 0;
  font-family: 'Duplicate Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Cabinet Grotesk';
}

.fund-bg {
  /* The image used */
  background-image: url("./Assets/images/fund.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  position: relative;
}
.list-bg {
  /* The image used */
  background-image: url("./Assets/images/3rd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  position: relative;
}
.review-bg {
  /* The image used */
  background-image: url("./Assets/images/revieww.png");
  
  background-repeat: no-repeat;
  background-size: cover;
 
  position: relative;
}
.why-bg {
  /* The image used */
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Assets/images/y.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  position: relative;
}
.why-bgg {
  /* The image used */
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Assets/images/y.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
 
  position: relative;
}
.map-container{
  width: 100%;
  height: 100%;
}
.invest-bg{
   /* The image used */
   background-image: url("./Assets/images/invest.png");
   background-position: b;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
}
.about-bg{
  /* The image used */
  background-image: url("./Assets/images/abb.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.con-bg{
  /* The image used */
  background-image: url("./Assets/images/conUs.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.term-bg{
  background-image: url("./Assets/images/termbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
